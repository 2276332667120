// Texticon

.texticon {
    .texticon-icon {
        font-size: 36px;
        margin-bottom: 0.875rem;
        line-height: 1;
        color: $primary;

        @include media-breakpoint-up(lg) {
            font-size: 46px;
            margin-bottom: 1.125rem;
        }
    }
}
